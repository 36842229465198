$( document ).ready(function() {

  // NAV DRAWER LOGIC

  var subToggle   = $('.drawer-toggle');
  var subToggleA  = $('.drawer-toggle_search');
  var subToggleB  = $('.drawer-toggle_follow');
  var subToggleC  = $('.drawer-toggle_subnav');

  // Open drawer
  subToggle.click(function(e){
    e.preventDefault;
    $('.drawer:hidden').velocity("slideDown", { duration: 300 });
  });

  // Search field
  subToggleA.click(function () {
    // If this content's already visible, toggle the drawer closed
    if ($('.drawer-search').is(':visible')) {
      $('.drawer > div:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.subnav:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.menu-marker:visible').stop().velocity("fadeOut", { duration: 200 });
      $('.drawer').stop().velocity("slideUp", { duration: 300 });
    } else {
      // Remove any content currently showing
      $('.drawer > div:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.menu-marker:visible').stop().velocity("fadeOut", { duration: 200 });
      $('.subnav:visible').stop().velocity("fadeOut", { duration: 300 });
      // Show the content
      $(this).find('.menu-marker').stop().velocity("fadeIn", { delay: 300, duration: 200 });
      $('.drawer-search:hidden').stop().velocity("fadeIn", { delay: 300, duration: 200, display: 'flex' });
    }
  });

  // Follow buttons and signup field
  subToggleB.click(function () {
    if ($('.drawer-follow').is(':visible')) {
      $('.drawer > div:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.subnav:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.menu-marker:visible').stop().velocity("fadeOut", { duration: 200 });
      $('.drawer').stop().velocity("slideUp", { duration: 300 });
    } else {
      $('.drawer > div:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.menu-marker:visible').stop().velocity("fadeOut", { duration: 200 });
      $('.subnav:visible').stop().velocity("fadeOut", { duration: 300 });
      $(this).find('.menu-marker').stop().velocity("fadeIn", { delay: 300, duration: 200 });
      $('.drawer-follow:hidden').stop().velocity("fadeIn", { delay: 300, duration: 200 });
    }
  });

  // Sub-navigation
  subToggleC.click(function () {
    if ($(this).parent().find('.subnav').is(':visible')) {
      $('.drawer > div:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.subnav:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.menu-marker:visible').stop().velocity("fadeOut", { duration: 200 });
      $('.drawer').stop().velocity("slideUp", { duration: 300 });
    } else {
      $('.drawer > div:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.subnav:visible').stop().velocity("fadeOut", { duration: 300 });
      $('.menu-marker:visible').stop().velocity("fadeOut", { duration: 200 });
      $(this).find('.menu-marker').stop().velocity("fadeIn", { delay: 300, duration: 200 });
      $(this).parent().find('.subnav').stop().velocity("fadeIn", { delay: 300, duration: 200, display: 'flex' });
    }
  });

  // Display Subnav if a subnav page is selected
  var activeSubnav = $('.active-subnav');

  // Check that there are child pages (or this is a child page) AND that the main nav is visible (so it doesn't activate at mobile breakpoints)
  if (activeSubnav.length && $('.nav-main').is(':visible')) {
    if (activeSubnav.closest('.subnav').is(':visible')) {

    } else {
      $('.drawer:hidden').velocity("slideDown", { duration: 300 });
      activeSubnav.parents('li').find('.menu-marker').stop().velocity("fadeIn", { delay: 150, duration: 200 });
      activeSubnav.closest('.subnav').stop().velocity("fadeIn", { delay: 0, duration: 100, display: 'flex' });
    }
  }


  // INFO DRAWER LOGIC

  var infoToggle   = $('.toggle-info-drawer');

  // Open info drawer
  infoToggle.click(function(e){
    e.preventDefault;
    var thisDrawer = $(this).parents('.listing').find('.info-drawer:hidden');

    $('.info-drawer:visible').stop().velocity("slideUp", {duration: 200});
    $(thisDrawer).velocity("slideDown", {
      delay: 100,
      duration: 300
    });
  });


  // SLIDER: MASTHEAD

  var mastheadSwiper = new Swiper ('.masthead-active', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    speed: 500,
    effect: 'slide',
    slidesPerView: 1,

    // Accessible for screen readers
    a11y: true,

    // Optimisation
    lazyLoading: true,
    lazyLoadingInPrevNext: true,

    // Controls
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.masthead .swiper-button-next',
    prevButton: '.masthead .swiper-button-prev',
  });


  // SLIDER: SHOWCASE

  var showcasePrimarySwiper = new Swiper ('.showcase-primary', {
    direction: 'horizontal',
    loop: false,
    speed: 300,
    effect: 'fade',
    slidesPerView: 1,

    // Accessible for screen readers
    a11y: true,

    // Controls
    pagination: '.showcase-primary .swiper-pagination',
    paginationClickable: true,
    nextButton: '.showcase-primary .swiper-button-next',
    prevButton: '.showcase-primary .swiper-button-prev',
  });


  // SLIDER: SHOWCASE FOR DETAIL PAGES
  var showcaseDetailSwiper = new Swiper ('.showcase-detail', {
    direction: 'horizontal',
    speed: 1000,
    effect: 'fade',
    slidesPerView: 1,
    autoplay: 10000,

    // Accessible for screen readers
    a11y: true,
    keyboardControl: true,

    // Controls
    pagination: '.showcase-detail .swiper-pagination',
    paginationClickable: true,
  });

  // SLIDER: SHOWCASE FOR VIDEO
  var showcaseVideoSwiper = new Swiper ('.showcase-video', {
    loop: false,
    speed: 300,
    slidesPerView: '2',
    spaceBetween: 40,
    slidesPerGroup: 2,

    breakpoints: {
      // when window width is <= 320px
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1
      },
      1200: {
        slidesPerView: 2,
        slidesPerGroup: 2
      }
    },

    // Accessible for screen readers
    a11y: true,

    // Controls
    pagination: '.showcase-video .swiper-pagination',
    paginationClickable: true,
    nextButton: '.showcase-video .swiper-button-next',
    prevButton: '.showcase-video .swiper-button-prev',
  });


  // SLIDER: CAROUSEL

  var mySwiper = new Swiper ('.carousel-related .swiper-container', {
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    slidesPerView: 'auto',
    // loopedSlides: 10,
    paginationClickable: true,
    spaceBetween: 60,

    // Accessible for screen readers
    a11y: true,

    // Controls
    nextButton: '.carousel .swiper-button-next',
    prevButton: '.carousel .swiper-button-prev',
  });

  // SLIDER: CAROUSEL RECENT (in-page carousels)

  // Multiple carousels on page, so we'll need to loop through them:
  // https://stackoverflow.com/questions/30885041/idangero-us-swiper-multiple-instances

  $('.carousel-recent .swiper-container').each(function(){
    new Swiper($(this), {
      nextButton: $(this).parent().find('.swiper-button-next'),
      prevButton: $(this).parent().find('.swiper-button-prev'),
      loop: false,
      slidesPerView: 'auto',
      centeredSlides: false,
      spaceBetween: 40,
      slidesOffsetBefore: 0,
      roundLengths: true,

      // Accessible for screen readers
      a11y: true,
    });
  });


  // TIMEAGO (Timestamping)

  $("time.timeago").timeago();


  // MODAAL

  $('.video-modal').modaal({
    type: 'video'
  });

  $('.gallery-modal').modaal({
    type: 'image'
  });

  $('.inline-modal').modaal();


  // PARTNERS SHOWCASE
  // Swiper instance opens inside Modaal instance
  // https://github.com/humaan/Modaal/issues/83

  // Set our slide index var initial value to 0.
  var partnerIndex = 0;

  // Count all the '.listing' elements on the page, and add an index number to each one as a data attribute
  $('.listing').each( function(i) {
    $(this).find(".partner-modal").attr("data-count", i);
  });


  $('.partner-modal').modaal({
    fullscreen: true,

    before_open: function(event) {

      var $clicked = $(event.currentTarget);

      // Use Number.parseInt if using ES2015.
      partnerIndex = parseInt($clicked.attr('data-count'), 10);
    },

    after_open: function() {
      showcasePartnersSwiper = new Swiper ('.modaal-container .showcase-partners', {
        initialSlide: partnerIndex,
        updateTranslate: true,
        direction: 'horizontal',
        loop: false,
        speed: 300,
        effect: 'slide',
        slidesPerView: 1,
        spaceBetween: 40,
        autoHeight: true,
        roundLengths: true,

        // Accessible for screen readers
        a11y: true,

        // Controls
        pagination: '.showcase-partners .swiper-pagination',
        paginationClickable: true,
        nextButton: '.showcase-partners .swiper-button-next',
        prevButton: '.showcase-partners .swiper-button-prev',
      });
    },
    before_close: function() {
      // Destroy Swiper as we close.
      showcasePartnersSwiper.destroy();
    }
  })

  // DIFFUSION LIVE SHOWCASE
  // Swiper instance opens inside Modaal instance
  // https://github.com/humaan/Modaal/issues/83

  // Set our slide index var initial value to 0.
  var listingIndex = 0;

  // Count all the '.listing' elements on the page, and add an index number to each one as a data attribute
  $('.section-live .listing').each( function(i) {
    $(this).find(".live-modal").attr("data-count", i);
  });


  $('.live-modal').modaal({
    fullscreen: true,
    custom_class: 'modaal-live',

    before_open: function(event) {

      var $clicked = $(event.currentTarget);

      // Use Number.parseInt if using ES2015.
      listingIndex = parseInt($clicked.attr('data-count'), 10);
    },

    after_open: function() {
      var showcaseLiveSwiperOuter = new Swiper ('.showcase-live-outer', {
        initialSlide: listingIndex,
        updateTranslate: true,
        direction: 'horizontal',
        loop: false,
        speed: 300,
        effect: 'fade',
        slidesPerView: 1,
        spaceBetween: 40,
        autoHeight: true,
        roundLengths: true,

        // Accessible for screen readers
        a11y: true,

        // Controls
        nextButton: '.showcase-live .swiper-button-next',
        prevButton: '.showcase-live .swiper-button-prev',
      });

      var showcaseLiveSwiperInner = new Swiper('.showcase-live-inner', {
        pagination: '.swiper-pagination-inner',
        paginationClickable: true,
        direction: 'horizontal',
        preloadImages: false,
        lazyLoading: true,
      });
    },
    before_close: function() {
      // Destroy Swiper as we close.
      // showcaseLiveSwiperOuter.destroy();
      // showcaseLiveSwiperInner.destroy();
    }
  })


  // GOOGLE MAPS - disable scroll-zooming unless map is clicked

  $('.map').click(function(){
    $(this).find('iframe').addClass('clicked')
  })
  .mouseleave(function(){
    $(this).find('iframe').removeClass('clicked')
  });


  // TAG CLOUD
  // Tag filtering system

  $('.tag a').click( function(e) {
    e.preventDefault();
    var selectedTag = $(this).data("tagselect");
    // '*=' means 'includes'
    var targets = $('.listing[data-tags*=' + selectedTag + ']');

    // First check if all tags are ON. If they are, we will switch them all OFF except for the one selected.
    // This is a friendlier way to initialise user interaction with the tag cloud

    // Count total tags in the DOM
    var totalTags = $('.tag a').length;
    // Count only the tags that are currently ON
    var selectedTags = $('.tag a.selected').length;

    // Compare the two, and if ALL tags are currently ON:
    if (totalTags === selectedTags) {
      // Toggle all other tags OFF
      $('.tag a').not(this).toggleClass('selected');
      // hide all listings, then show listings for the selected tag
      $('.listing').toggleClass('listing-show listing-hide');
      $('.listing').velocity("fadeOut", { duration: 200 });
      $(targets).toggleClass('listing-hide listing-show');
      $(targets).velocity("fadeIn", { duration: 200 });
    }

    // If selected tag is switched ON
    else if ($(this).hasClass('selected')) {
      // Switch it OFF
      $(this).toggleClass('selected');
      // Hide related listings if they are currently visible:
      if ($(targets).hasClass('listing-show')) {
        $(targets).toggleClass('listing-show listing-hide');
        $(targets).velocity("fadeOut", { duration: 200 });
      }
    }
    // If selected tag is switched OFF
    else {
      // Switch it ON
      $(this).toggleClass('selected');
      // Show related listings if they are currently hidden:
      if ($(targets).hasClass('listing-hide')) {
        $(targets).toggleClass('listing-hide listing-show');
        $(targets).velocity("fadeIn", { duration: 200 });
      }
    }
  });


  // DATE FILTER
  // Add a class to today's date
  $('.date-day a').each( function() {
    // return today's date passed by Twig through a hidden element
    var today = $('.date-today').attr('data-date');
    // if a day matched today's date, give the parent element a class name
    if ( $(this).attr('data-date') == today ) {
      $(this).parent().addClass('flag-today');
    }
  });

  $('.date-day a').click( function(e) {
    e.preventDefault();
    var tagDate = $(this).attr('data-date');
    var listings = $('.listing');

    // On page load, all events will be listed. When calendar is first interacted with, remove all listings except for the one selected.
    if ($(this).hasClass('initial')) {
      $('.date-picker .date-day a').removeClass('initial');
      $(listings).toggleClass('listing-show listing-hide');
      $(listings).velocity("fadeOut", {duration: 200});
    }

    if ($(this).hasClass('selected')) {
      $(listings).each(function () {
        var listingStart = $(this).attr('data-start');
        var listingEnd = $(this).attr('data-end');

        if (listingStart <= tagDate && listingEnd >= tagDate) {
          // Hide Listing:
          // if listing is currently visible:
          if ($(this).hasClass('listing-show')) {
            $(this).toggleClass('listing-show listing-hide');
            $(this).velocity("fadeOut", {duration: 200});
          }
        }
      });
    } else {
      $(listings).each(function () {
        var listingStart = $(this).attr('data-start');
        var listingEnd = $(this).attr('data-end');

        if (listingStart <= tagDate && listingEnd >= tagDate) {

          // Show Listing:
          // if listing is currently hidden:
          if ($(this).hasClass('listing-hide')) {
            $(this).toggleClass('listing-hide listing-show');
            $(this).velocity("fadeIn", {duration: 200});
          }
        }
      });
    }
    $(this).toggleClass('selected');
  });

  // SHOW ALL
  // Resets all filters
  $('.btn-reset').click( function (e) {
    e.preventDefault();
    if ($('.listing').hasClass('listing-hide')) {
      $('.listing').removeClass('listing-hide');
      $('.listing').addClass('listing-show');
      $('.listing').velocity("fadeIn", {duration: 200});
    }
    // reset datepicker buttons
    $('.date-picker .date-day a').removeClass();
    $('.date-picker .date-day a').addClass('initial');

    // reset filter buttons
    $('.tag a').addClass('selected');
  });
});